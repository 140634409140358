const form = document.querySelector("form");
form?.addEventListener("submit", (event) => {
  event.preventDefault();
  //console.log(form.nameInput.value);
  //console.log(form.emailInput.value);

  var xhr = new XMLHttpRequest();

  xhr.onload = function (e) {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        alert("Teilnahme erfolgreich!");
      } else {
        console.error("Giveaway: Request error. Response: " + xhr.statusText);
      }
    }
  };
  xhr.open(
    "GET",
    `/giveaway.php?email=${form.emailInput.value}&name=${
      form.nameInput.value
    }&address=${form.adresseInput.value}&city=${form.ortInput.value}&zip=${
      form.plzInput.value
    }&tel=${
      form.telInput.value ? form.telInput.value : "keine Nummer angegeben"
    }&newsletter=${form.newsletterInput.checked ? "ja" : "nein"}`,
    true
  );
  xhr.send();
});
