// Carousel
function carouselSlider(
  element: string,
  tag = "img",
  item = "image",
  interval = 5000,
  reverse = false
) {
  const carousel = document.querySelector<HTMLDivElement>(
    `.${element}__carousel`
  );
  const items = carousel!.querySelectorAll<HTMLElement>(tag);
  let width: number;

  const setSlidePosition = (image: HTMLElement, index: number) => {
    image.style.left = `${width * index}px`;
  };

  const initSlides = () => {
    width = items[0].getBoundingClientRect().width;
    for (let i = 0; i < items.length; i++) {
      setSlidePosition(items[i], i);
    }
  };

  initSlides();

  window.addEventListener("resize", initSlides);

  setInterval(() => {
    const currentItem = document.querySelector<HTMLElement>(
      `.${element}__${item}--current`
    );
    let nextItem = currentItem!.nextElementSibling;
    if (nextItem === null) {
      nextItem = carousel!.querySelector<HTMLElement>(tag);
    }
    const amountToMove = (nextItem as HTMLElement).style.left;
    carousel!.style.transform = `translateX(${
      reverse ? "" : "-"
    }${amountToMove})`;
    currentItem!.classList.remove(`${element}__${item}--current`);

    nextItem!.classList.add(`${element}__${item}--current`);
  }, interval);
}

carouselSlider("about");
//carouselSlider("stories", ".stories__item", "item");
